/* JS for Styling */

(function ($) {

	"use strict";


	if(jQuery('#region_map').length!=0) {
		jQuery('#region_map .wrapper').lockRatio('width', (1280/418));

		/* Region dropdown */
		var regionUL = jQuery('#region ul');
		var dropdown = jQuery('<div class="dropdown mobileOnly"><div class="toggle">Regions</div></div>');
		var regionUL2 = regionUL.clone();
		regionUL2.removeClass('table');
		dropdown.append(regionUL2);
		regionUL.addClass('mobileHide');
		regionUL.after(dropdown);
		dropdown.find('.toggle').click(function(){
			regionUL2.slideToggle(400);
			dropdown.toggleClass('show');
		});
		/* Endof Region dropdown */

		jQuery(".product-categories__categories").change(function(){
			var url = jQuery(this).val();
			window.location.href = url;
		});

	}

})(jQuery);